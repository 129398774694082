var axios = require('axios');

var axiosInstance = axios.create({
  // baseURL: 'http://localhost:5000/benz/'
 // baseURL: 'http://164.52.216.164:5000/benz/'
 // baseURL:"http://34.131.152.185:5000/benz/"
  baseURL:"https://dm-autometrics.in/benz/"
  /* other custom settings */
});

module.exports = axiosInstance;
