import React, { Component } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import {getHyphenDDMMMYYYYHHMM} from '../common/utils';
var redirectURL = require('../redirectURL');
var moment = require('moment');


export default class PEPODPrint extends Component {
    constructor(props) {
		super(props);
		this.state = {
            show:false,
            basicTitle:"",
            basicType:"default",
            rowData:[],
            rownode:[],
            loadshow:"show-n",
            overly:"show-n",
            items:[]
        }
        this.onLoadGetShipments=this.onLoadGetShipments.bind(this);
        this.onClickPrintDoc = this.onClickPrintDoc.bind(this);
    }
    componentDidMount(){
        if(localStorage.getItem("customer_code") != "" && localStorage.getItem("customer_code") != undefined && localStorage.getItem("customer_code") != "undefined")
        {
            var customer_code = localStorage.getItem("customer_code");
        }
        else{
            var customer_code = "";
        }
        this.setState({
            customer_code:customer_code
        })
        if(this.props.match.params.invoice_no != "" && this.props.match.params.invoice_no != undefined)
        {
            var invoice_no = this.props.match.params.invoice_no;
        }
        else{
            var invoice_no = "";
        }
        if(invoice_no == "")
        {
            this.setState({
                show:true,
                basicTitle:"No Data found",
                basicType:"danger"
            })
        }
        else{
            if(localStorage.getItem("user_type") === "APP_ADMIN" || localStorage.getItem("user_type") === "ADMIN")
            {
                if(invoice_no !== "")
                {
                    var p = {
                        invoice_no:invoice_no
                    }
                    redirectURL.post("/dashboard/invoiceData",p)
                    .then((response) =>{
                        var records = response.data.records;
                        console.log("records",records)
                        if(records.length > 0)
                        {
                            var parameters = {
                                dealer_code:records[0].parent_dealer_code,
                                invoice_no:invoice_no
                            }
                            this.onLoadGetShipments(parameters);
                                        
                        }
                    })
                }
            }
            else{
                var parameters = {
                    dealer_code:customer_code,
                    invoice_no:invoice_no
                }
                this.onLoadGetShipments(parameters);
            }
        }
    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload();
    }
    onLoadGetShipments(parameters){
        this.setState({
            loadshow:"show-m"
        })
        if(localStorage.getItem("user_type") === "APP_ADMIN" || localStorage.getItem("user_type") === "ADMIN")
        {
            var urlpath ="/dashboard/parentcustomerconsignments" 
        }
        else{
         
            if(localStorage.getItem("user_type") === "PARENT_DEALER")
            {
                var urlpath ="/dashboard/parentcustomerconsignments" 
            }
            else{
                var urlpath ="/dashboard/customerconsignments"
            }
        }
        redirectURL.post(urlpath,parameters)
        .then((response) => {
            console.log("response",response)
            if(response.data.status == "success")
            {
                var records = response.data.records;
                var rowData=[];
                var recordsarr = [];
                var podsubmitdata = []
                var nonpoddata = [];
                var withoutdefects=[];
                var withdefects=[];
                var withoutdefectspod=[];
                var withdefectspod=[];
                var approvedpods = [];
                var itemslist=[];
                try{
                    if(records.length > 0)
                    {
                        records.map((item) => {
                            if(item.is_pod_submit != "" && item.is_pod_submit != undefined)
                            {
                                if(item.pods != "" && item.pods != undefined)
                                {
                                    if(item.pods.length > 0)
                                    {
                                        //  console.log("item.pods", item.pods);
                                        // item.truck_no = item.pods[0].truck_no;
                                        var isdefec = 0;
                                        item.pods.map((jt) => {
                                            jt.truck_no = item.truck_no;
                                            jt.invoice_date_frt = getHyphenDDMMMYYYYHHMM(jt.invoice_date);
                                            jt.customer_pod_date_frt = getHyphenDDMMMYYYYHHMM(jt.customer_pod_date);
                                            jt.tpt_code = getHyphenDDMMMYYYYHHMM(item.transporter_code);
                                            jt.tpt_name = getHyphenDDMMMYYYYHHMM(item.transporter_name);
                                            if(jt.is_defect_item == 1)
                                            {
                                                isdefec=1;
                                                withdefects.push(jt);
                                            }
                                            else{
                                                withoutdefects.push(jt);
                                            }
                                            itemslist.push(jt);
                                        })
                                        if(isdefec == 1)
                                        {
                                            withdefectspod.push(item)
                                        }
                                        else{
                                            withoutdefectspod.push(item)
                                        }
                                        
                                    }
                                }
                                
                                podsubmitdata.push(item);
                                if(item.is_approve_pod == 1)
                                {
                                    approvedpods.push(item);
                                }
                            }
                            else{
                                item.invoice_document_1 = "";
                                item.invoice_document_2 = "";
                                item.is_pod_submit= "";
                                nonpoddata.push(item)
                            }
                            item.inv_date = getHyphenDDMMMYYYYHHMM(item.invoice_time);
                            // console.log("item.inv_date",item.inv_date )
                            item.rec_dealer_reported = getHyphenDDMMMYYYYHHMM(item.recent_dealer_reported);
                            // console.log("item.rec_dealer_reported",item.rec_dealer_reported )
                            item.consigner_name = "Daimler India Commercial Vehicles Pvt Ltd"

                            recordsarr.push(item);
                        })
                    }
                }
                catch(e){

                }

                // console.log("rowData ", recordsarr)
                this.setState({
                    rowData:recordsarr,
                    items:itemslist,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
            
        });

    }
    onClickPrintDoc(){
        var printContents = document.getElementById("printDoc").innerHTML;
        console.log("printContents",printContents)
        var originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();

        document.body.innerHTML = originalContents;
    }

  render() {
    return (
        <div className="container-fluid" style={{marginBottom:"60px"}}>
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            {console.log("this.state.rowData ", this.state.rowData)}
            {(this.state.rowData.length > 0)?
            <div className="row" id="printDoc" style={{padding:"30px"}}>
                <div className="col-sm-6">
                    <img className="float-left" src={require('../../assets/icons/logo.png')} style={{width:"200px"}} />
                </div>
                <div className="col-sm-6" style={{float:'right',textAlign:"right"}}>
                    <img className="float-right" src={require('../../assets/icons/enmovil.png')} />
                </div>
                <div style={{float:"left",width:"100%",marginTop:"30px"}}>
                    <div style={{float:"left",width:"30%"}}>
                        <span >Serial No: </span>
                        <span style={{fontWeight:"bold"}}>{this.state.rowData[0].invoice_no}</span>
                    </div>
                    <div style={{float:"left",width:"30%"}}>
                        <span >Invoice Date: </span>
                        <span style={{fontWeight:"bold"}}>{this.state.rowData[0].inv_date}</span>
                    </div>
                    <div style={{float:"left",width:"30%"}}>
                        <span >Transporter: </span>
                        <span style={{fontWeight:"bold"}}>{this.state.rowData[0].transporter_name} &nbsp; Code: {this.state.rowData[0].transporter_code}</span>
                    </div>
                </div>
                <div style={{float:"left",width:"100%",marginTop:"30px"}}>
                    <div style={{float:"left",width:"30%"}}>
                        <span >Reached Destination At: </span>
                        <span style={{fontWeight:"bold"}}>{this.state.rowData[0].rec_dealer_reported}</span>
                    </div>
                    <div style={{float:"left",width:"30%"}}>
                        <span >Vin No: </span>
                        <span style={{fontWeight:"bold"}}>{(this.state.rowData[0].vin_number != "" && this.state.rowData[0].vin_number != undefined)?this.state.rowData[0].vin_number:this.state.rowData[0].truck_no}</span>
                    </div>
                    <div style={{float:"left",width:"30%"}}>
                        <span >Supplying Plant: </span>
                        <span style={{fontWeight:"bold"}}>{this.state.rowData[0].consigner_name}</span>
                    </div>
                </div>
                
                <div style={{float:"left",width:"100%",marginTop:"30px"}}>
                    <table style={{width:"100%"}} border="1" cellPadding="5" cellSpacing={"0"}>
                        <tr>
                            <th style={{width:"140px"}}>Code</th>
                            <th style={{width:"300px"}}>Material</th>
                            <th style={{width:"150px"}}>Supplying Plant</th>
                            <th style={{width:"150px"}}>POD Date</th>
                            <th style={{width:"150px"}}>Item</th>
                            <th style={{width:"100px"}}>Defect</th>
                            <th style={{width:"150px"}}>Category</th>
                            <th style={{width:"150px"}}>Quantity</th>
                            <th style={{width:"300px"}}>Remarks</th>
                            <th style={{width:"600px"}}>Documents</th>
                        </tr>
                        {(this.state.items.length > 0)?
                        (this.state.items.map(itm => 
                            <tr>
                                <td>
                                    {itm.pod_no}
                                </td>
                                <td>
                                    Code: {itm.material_no}<br />
                                    Description: {itm.material_desc}
                                </td>
                                <td>
                                    {itm.supply_plant_desc}
                                </td>
                                <td>
                                    {getHyphenDDMMMYYYYHHMM(itm.customer_pod_date)};
                                </td>
                                <td>
                                    
                                    Code: {itm.item_code}<br />
                                    Description: {itm.item_description}
                                </td>
                                <td>
                                    Code: {itm.defect_code}<br />
                                    Description:{itm.defect_description}
                                </td>
                                <td>
                                    {itm.category}
                                </td>
                                <td>
                                    {itm.quantity}
                                </td>
                                <td>
                                    {itm.customer_pod_remarks}
                                </td>
                                <td>
                                    {
                                     (itm.invoice_document_1 != "" && itm.invoice_document_1 != undefined)?
                                        (itm.invoice_document_1.length > 0)?
                                        itm.invoice_document_1.map((im=>
                                            <li>{im}</li>
                                            ))
                                            
                                        :""   
                                     :""   
                                    }
                                    
                                    {
                                     (itm.invoice_document_2 != "" && itm.invoice_document_2 != undefined)?
                                        (itm.invoice_document_2.length > 0)?
                                        itm.invoice_document_2.map((im=>
                                            <li>{im}</li>
                                            ))
                                            
                                        :""   
                                     :""   
                                    }
                                    
                                </td>
                            </tr>
                            
                        ))
                        :""}
                        
                    </table>
                </div>
            </div>
            :""}
            <div className="col-sm-12" style={{position:"fixed",height:"80px", bottom:"0px",left:"0px",textAlign:"center"}}>
                <button type="button" className="btn btn-success" onClick={this.onClickPrintDoc}>Print</button>
            </div>          
        </div>    
    )
  }
}
